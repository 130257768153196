.extra-value {
  border-radius: 200px;
  padding: 10px;
  background-color: var(--grey);
  display: flex;
  align-items: center;
  gap: 8px;
}

.extra-clear {
  outline: none;
  border: none;
  background-color: var(--dark-grey);
  color: var(--white);
  display: inline-flex;
  align-items: center;
  border-radius: 200px;
  cursor: pointer;
  padding: 4px;
  transition: all 0.3s;

  &:hover {
    background-color: var(--box-shadow);
  }
}
