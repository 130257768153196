.container_check {
  direction: rtl;
  display: block;
  box-sizing: border-box;
  padding: 0;
  font-size: 10px;
  color: black;

  .check_header {
    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    padding: 0 6px;
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: start;

    .order-info {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      max-width: 400px;
      row-gap: 8px;
      padding: 0 8px;
    }
  }

  .check_table {
    color: black !important;

    .product-name-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
    }

    .addon-name-wrapper {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    .ant-table-cell {
      font-size: 12px;
      padding: 10px;
      border-collapse: collapse;
    }

    tr {
      th,
      td {
        border-bottom-color: black;
        border-bottom-style: dashed;
      }

      &:last-child td {
        border-bottom-style: solid;
      }
    }
  }

  footer {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 20px 6px;

    span,
    h1 {
      color: rgb(0, 0, 0);
    }

    h1 {
      font-size: 16px;
    }
  }

  hr {
    margin: 10px 0;
    border-style: solid;
    border-color: black;
    border-top-width: 0;
  }

  .copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
  }
}

// [data-theme='dark'] .container_check {
//   display: block;
//   box-sizing: border-box;
//   .check_header {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     height: 200px;
//     padding: 50px;
//     .check_companyInfo {
//       text-align: end;
//     }
//     .check_icon {
//       font-size: 60px;
//       color: #b3bdd1;
//     }
//     span,
//     h1,
//     h5 {
//       color: #b3bdd1;
//     }
//   }
//   main {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 50px;
//   }

//   footer {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 50px;
//     span,
//     h1,
//     h5 {
//       color: #b3bdd1;
//     }
//   }
// }
